document.querySelector('.play-game').addEventListener('click', function() {
    var iframe = document.getElementById('game-frame');
    iframe.src = iframe.getAttribute('data-src');
    document.querySelector('.o-game__wrapper').style.display = 'none';
});
window.addEventListener('scroll', function() {
    const bonusIsland = document.getElementById('bonus-island');

    console.log(bonusIsland);
    
    if (window.scrollY > 50) {
      bonusIsland.classList.add('show');
    }
});